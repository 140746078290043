import Layout from '@/layout'
export let luocha = {
  path: '/check',
  name: 'Check',
  component: Layout,
  redirect: '/check/group',
  meta: {
    title: '账号落查',
    icon: 'el-icon-s-custom'
  },
  children: [
    {
      path: 'accountCheck',
      name: 'CheckAccountCheck',
      component: () => import('@/views/check/AccountCheck.vue'),
      meta: {
        title: '账号查手机',
        icon: 'el-icon-search'
      }
    },
    {
      path: 'phoneCheck',
      name: 'PhoneCheck',
      component: () => import('@/views/check/PhoneCheck.vue'),
      meta: {
        title: '手机查账号',
        icon: 'el-icon-phone'
      }
    }
  ]
}

//次数
export let record = [
  {
    path: 'record',
    name: 'CheckRecord',
    component: () => import('@/views/check/record.vue'),
    meta: {
      title: '查询记录',
      icon: 'el-icon-document-copy',
      isYs: 1
    }
  }
]
//  积分
export let checkArr = [
  {
    path: 'integrate',
    name: 'Integrate',
    component: () => import('@/views/check/integrate.vue'),
    meta: {
      title: '积分使用记录',
      icon: 'el-icon-document',
      isYs: 0
    }
  },
  {
    path: 'right',
    name: 'CheckRight',
    component: () => import('@/views/check/right.vue'),
    meta: {
      title: '落查命中率',
      icon: 'el-icon-s-claim',
      isYs: 0
    }
  }
]
