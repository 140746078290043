import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import addRouteFun from './jurisdiction'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  // 群组详情
  {
    path: '/group',
    component: Layout,
    redirect: '/group/detail',
    name: 'groupPage',
    hidden: true,
    children: [
      {
        meta: {
          keepAlive: true
        },
        path: 'detail/:groupId',
        name: 'GroupDetail',
        component: () => import('@/views/detail/group/GroupDetail.vue')
      }
    ]
  },
  // 用户详情
  {
    path: '/user',
    component: Layout,
    redirect: '/user/detail',
    name: 'userPage',
    hidden: true,
    children: [
      {
        meta: {
          keepAlive: true
        },
        path: 'detail/:userId',
        name: 'userDetail',
        component: () => import('@/views/detail/user/UserDetail.vue')
      }
    ]
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Layout,
    redirect: '/monitor/subreddit',
    meta: {
      title: '方案管理',
      icon: 'el-icon-aim'
    },
    children: [
      {
        path: 'keyword',
        name: 'MonitorKeyword',
        component: () => import('@/views/monitor/Index.vue'),
        meta: {
          title: '关键词方案',
          icon: 'el-icon-magic-stick'
        }
      }
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Layout,
    redirect: '/search/users',
    meta: {
      title: '数据检索',
      icon: 'el-icon-search'
    },
    children: [
      {
        path: 'key',
        name: 'SearchKey',
        component: () => import('@/views/search/Index.vue'),
        meta: {
          title: '关键词检索',
          icon: 'el-icon-office-building',
          noRead: true,
          noReadKey: 'userKeyMap'
        }
      },
      {
        path: 'users',
        name: 'SearchUsers',
        component: () => import('@/views/search/Index2.vue'),
        meta: {
          title: '账号检索',
          icon: 'el-icon-zoom-in'
        }
      },
      {
        path: 'posts',
        name: 'SearchPosts',
        component: () => import('@/views/search/Index3.vue'),
        meta: {
          title: '群组检索',
          icon: 'el-icon-tickets'
        }
      },
      {
        path: 'index',
        name: 'SearchPosts4',
        component: () => import('@/views/search/Index4.vue'),
        meta: {
          title: '群组索引',
          icon: 'el-icon-c-scale-to-original'
        }
      }
    ]
  },
  {
    path: '/mark',
    name: 'Mark',
    component: Layout,
    redirect: '/mark/info',
    meta: {
      title: '我的标记',
      icon: 'el-icon-s-flag'
    },
    children: [
      {
        path: 'info',
        name: 'MarkInfo',
        component: () => import('@/views/mark/Index.vue'),
        meta: {
          title: '标记的消息',
          icon: 'el-icon-chat-dot-square'
        }
      },
      {
        path: 'account',
        name: 'MarkInfoAccount',
        component: () => import('@/views/mark/Index2.vue'),
        meta: {
          title: '标记的账号',
          icon: 'el-icon-user',
          noRead: true,
          noReadKey: 'userRemarkMap'
        }
      },
      {
        path: 'group',
        name: 'MarkGroup',
        component: () => import('@/views/mark/Index3.vue'),
        meta: {
          title: '标记的群组',
          icon: 'el-icon-tickets',
          noRead: true,
          noReadKey: 'tipChannelMap'
        }
      },
      {
        path: 'management',
        name: 'MarkManagement',
        component: () => import('@/views/mark/Index4.vue'),
        meta: {
          title: '群标记管理',
          icon: 'el-icon-connection'
        }
      }
    ]
  },
  // {
  //   path: '/check',
  //   name: 'Check',
  //   component: Layout,
  //   redirect: '/check/group',
  //   meta: {
  //     title: '账号落查',
  //     icon: 'el-icon-s-custom'
  //   },
  //   children: [
  //     {
  //       path: 'accountCheck',
  //       name: 'CheckAccountCheck',
  //       component: () => import('@/views/check/AccountCheck.vue'),
  //       meta: {
  //         title: '账号查手机',
  //         icon: 'el-icon-search'
  //       }
  //     },
  //     {
  //       path: 'phoneCheck',
  //       name: 'PhoneCheck',
  //       component: () => import('@/views/check/PhoneCheck.vue'),
  //       meta: {
  //         title: '手机查账号',
  //         icon: 'el-icon-phone'
  //       }
  //     }
  //     // {
  //     //   path: 'integrate',
  //     //   name: 'Integrate',
  //     //   component: () => import('@/views/check/integrate.vue'),
  //     //   meta: {
  //     //     title: '积分使用记录',
  //     //     icon: 'el-icon-document'
  //     //   }
  //     // },
  //     // {
  //     //   path: 'right',
  //     //   name: 'Right',
  //     //   component: () => import('@/views/check/right.vue'),
  //     //   meta: {
  //     //     title: '落查命中率',
  //     //     icon: 'el-icon-s-claim'
  //     //   }
  //     // },
  //     // {
  //     //   path: 'record',
  //     //   name: 'Record',
  //     //   component: () => import('@/views/check/record.vue'),
  //     //   meta: {
  //     //     title: '查询记录',
  //     //     icon: 'el-icon-document-copy'
  //     //   }
  //     // }
  //   ]
  // },
  // {
  //   path: '/geofencing',
  //   name: 'geofencing',
  //   component: Layout,
  //   redirect: '/geofencing',
  //   meta: {
  //     title: '地理围栏',
  //     icon: 'el-icon-location-outline'
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'CuttingPush',
  //       component: () => import('@/views/geofencing/List.vue'),
  //       meta: {
  //         title: '地理围栏',
  //         icon: 'el-icon-add-location'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/add',
    name: 'Add',
    component: Layout,
    redirect: '/add/group',
    meta: {
      title: '添加群',
      icon: 'el-icon-circle-plus'
    },
    children: [
      {
        path: 'group',
        name: 'AddGroup',
        component: () => import('@/views/add/Group.vue'),
        meta: {
          title: '提交群信息',
          icon: 'el-icon-chat-dot-round'
        }
      }
      // {
      //   path: 'account',
      //   name: 'AddAccount',
      //   component: () => import('@/views/add/Account.vue'),
      //   meta: {
      //     title: '提交账号',
      //     icon: 'el-icon-user'
      //   }
      // }
    ]
  },
  {
    path: '/cutting',
    name: 'cutting',
    component: Layout,
    redirect: '/cutting/push',
    meta: {
      title: '情报',
      icon: 'el-icon-aim'
    },
    children: [
      {
        path: 'push',
        name: 'CuttingPush',
        component: () => import('@/views/cutting/Cutting.vue'),
        meta: {
          title: '情报推送',
          icon: 'el-icon-magic-stick'
        }
      }
    ]
  },
  {
    path: '/import',
    name: 'import',
    component: Layout,
    redirect: '/import/log',
    meta: {
      title: '导出',
      icon: 'el-icon-aim'
    },
    children: [
      {
        path: 'log',
        name: 'ImportLog',
        component: () => import('@/views/import/Log.vue'),
        meta: {
          title: '导出记录',
          icon: 'el-icon-bottom'
        }
      }
    ]
  },

  // {
  //   path: '/system',
  //   name: 'System',
  //   hide: true,
  //   component: Layout,
  //   redirect: '/system/users',
  //   meta: {
  //     title: '系统管理',
  //     icon: 'el-icon-s-tools'
  //   },
  //   children: [
  //     {
  //       path: 'users',
  //       name: 'Users',
  //       component: () => import('@/views/system/users.vue'),
  //       meta: {
  //         title: '用户管理',
  //         icon: 'el-icon-user'
  //       }
  //     },
  //     {
  //       path: 'logs',
  //       name: 'Logs',
  //       component: () => import('@/views/system/logs.vue'),
  //       meta: {
  //         title: '登录日志',
  //         icon: 'el-icon-document'
  //       }
  //     },
  //     {
  //       path: 'checklogs',
  //       name: 'Checklogs',
  //       component: () => import('@/views/system/checklogs.vue'),
  //       meta: {
  //         title: '检索日志',
  //         icon: 'el-icon-document'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/login/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

addRouteFun(router)

router.beforeEach((to, form, next) => {
  next()
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
