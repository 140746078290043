import { luocha, record, checkArr } from './luocha'
function addRouteFun(routerObj) {
  let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  // 根据isYs，向账号落查里面添加指定菜单
  if (
    userInfo &&
    userInfo.isYs == 1 &&
    userInfo.userAccount.indexOf('JW58') == -1
  ) {
    // 这个判断是为了禁止重复添加
    if (
      !luocha.children.some(ele => {
        return ele.path == 'record'
      })
    ) {
      luocha.children.push(...record)
    }
  } else if (
    userInfo &&
    userInfo.isYs == 0 &&
    userInfo.userAccount.indexOf('JW58') == -1
  ) {
    // 这个判断是为了禁止重复添加
    if (
      !luocha.children.some(ele => {
        return ele.path == 'integrate'
      })
    ) {
      luocha.children.push(...checkArr)
    }
  }

  // 账号JW**禁止查看 落查 菜单
  if (userInfo) {
    if (userInfo.userAccount.indexOf('JW58') == -1) {
      addFun(routerObj, [luocha], '/')
    }
  }
}

function addFun(routerObj, arr, parentName) {
  if (arr.length > 0) {
    arr.forEach(item => {
      if (routerObj.options.routes) {
        routerObj.options.routes.forEach(element => {
          if (element.name == parentName) {
            if (
              !element.children.some(n => {
                return n.name == item.name
              })
            ) {
              element.children.push(item)
            }
          }
        })
      }
      routerObj.addRoute(parentName, item)
    })
  }
}

function delRouterItem(routes, delItem) {
  if (!Array.isArray(routes)) return
  routes.forEach((item, i) => {
    if (delItem == item.name) {
      routes.splice(i, 1)
    } else {
      if (item.children) {
        delRouterItem(item.children, delItem)
      }
    }
  })
}

export default addRouteFun
